// library imports
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

// local imports
import { API_BASE_URL } from "../config";

const AddPost = (props) => {
  let history = useHistory();
  const [title, setTitle] = useState("");
  const [username, setUsername] = useState("");
  const [content, setContent] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    await fetch(API_BASE_URL + "/api/posts", {
      method: "POST",
      body: JSON.stringify({
        title,
        username,
        content,
      }),
    });
    history.push("/");
  };

  return (
    <div>
      <h1>Add Post</h1>
      <form onSubmit={handleSubmit}>
        <label>title:</label>
        <input
          type="text"
          id="title"
          name="title"
          onChange={(e) => setTitle(e.target.value)}
        />
        <label>username:</label>
        <input
          type="text"
          id="username"
          name="username"
          onChange={(e) => setUsername(e.target.value)}
        />
        <label>content:</label>
        <input
          type="text"
          id="content"
          name="content"
          onChange={(e) => setContent(e.target.value)}
        />
        <input type="submit" value="Submit" />
      </form>
    </div>
  );
};

export default AddPost;
