// library imports
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

// local imports
import { API_BASE_URL } from "../config";

const Post = ({ post }) => {
  const [vote, updateVote] = useState(post.vote);
  return (
    <div className="postCard">
      <div className="content">
        <div className="leftPost">
          <div
            className="upvote"
            onClick={async () => {
              await fetch(API_BASE_URL + "/api/changeVote", {
                method: "POST",
                body: JSON.stringify({
                  id: parseInt(post.id),
                  vote: 1,
                }),
              });

              updateVote(vote + 1);
            }}
          />
          {vote}
          <div
            className="downvote"
            onClick={async () => {
              await fetch(API_BASE_URL + "/api/changeVote", {
                method: "POST",
                body: JSON.stringify({
                  id: post.id,
                  vote: -1,
                }),
              });

              updateVote(vote - 1);
            }}
          />
        </div>
        <div className="rightPost">
          <div className="title">{post.title}</div>
          <div className="username">{post.username}</div>
          <div className="content">{post.content}</div>
        </div>
      </div>
      <div className="comments">
        <Comments post={post} />
      </div>
    </div>
  );
};

const Comments = ({ post }) => {
  const [commentflag, changeCommentFlag] = useState(false);
  const [comment, setComment] = useState("");

  return (
    <div>
      {commentflag ? (
        <div>
          <input onChange={(e) => setComment(e.target.value)} />
          <button
            onClick={async () => {
              await fetch(API_BASE_URL + "/api/addComment", {
                method: "POST",
                body: JSON.stringify({
                  id: post.id,
                  comment: comment,
                }),
              });
            }}
          >
            submit
          </button>
        </div>
      ) : (
        <button
          style={{ background: "none", border: "none" }}
          onClick={changeCommentFlag}
        >
          Add Comment
        </button>
      )}

      {post.comment.map((com) => {
        return <div className="comment">{com}</div>;
      })}
    </div>
  );
};

const Posts = () => {
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    const getPosts = async () => {
      const resp = await fetch(API_BASE_URL + "/api/posts", { method: "GET" });
      let responseData = await resp.json();
      setPosts(responseData.posts);
    };

    getPosts();
  }, []);

  return (
    <div className="postsContainer">
      <h1>Posts</h1>
      <Link to="/addPost">Create a post</Link>
      {posts.map((post) => {
        return <Post post={post} key={post.id} />;
      })}
    </div>
  );
};

export default Posts;
