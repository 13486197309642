import logo from "./logo.svg";
import "./App.css";

import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Posts from "./components/posts";
import AddPost from "./components/addPost";

function App() {
  return (
    <Router>
      <Switch>
        <Route path="/addPost">
          <AddPost />
        </Route>
        <Route path="/">
          <Posts />
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
